.example {
  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  .tab-content {
    background-color: $light-50 !important;

    @at-root .dark-theme & {
      background-color: rgba(255, 255, 255, .1) !important;
    }
  }

  code[class*="language-"],
  pre[class*="language-"] {
    font-size: .875rem !important;
  }

  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    background: transparent;
  }

  & + p {
    margin-top: 1.5rem
  }

  // Components examples
  .preview,
  .preview .col {
    + p {
      margin-top: 2rem;
    }

    > .form-control {
      + .form-control {
        margin-top: .5rem;
      }
    }

    > .nav + .nav,
    > .alert + .alert,
    > .navbar + .navbar,
    > .progress + .progress {
      margin-top: 1rem;
    }

    > .dropdown-menu {
      position: static;
      display: block;
    }

    > :last-child {
      margin-bottom: 0;
    }

    // Images
    > svg + svg,
    > img + img {
      margin-left: .5rem;
    }

    // Buttons
    > .btn,
    > .btn-group {
      margin: .25rem .125rem;
    }
    > .btn-toolbar + .btn-toolbar {
      margin-top: .5rem;
    }

    // List groups
    > .list-group {
      max-width: 400px;
    }

    > [class*="list-group-horizontal"] {
      max-width: 100%;
    }

    // Navbars
    .fixed-top,
    .sticky-top {
      position: static;
      margin: -1rem -1rem 1rem;
    }

    .fixed-bottom {
      position: static;
      margin: 1rem -1rem -1rem;
    }

    @include media-breakpoint-up(sm) {
      .fixed-top,
      .sticky-top {
        margin: -1.5rem -1.5rem 1rem;
      }
      .fixed-bottom {
        margin: 1rem -1.5rem -1.5rem;
      }
    }

    // Pagination
    .pagination {
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }
}


.Dockare-pageHeader{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #4F4F4F;

}

.dropdown-toggle::after {
  display: none !important; 
}

.bg-dockareWhite{
  // background-color: rgba(248, 248, 248, 1);
  background-color: white;
}

.bg-dockareError{
  background-color: rgba(178, 26, 26, 0.2);
}

.bg-dockareSuccess{
  background-color: rgba(32, 107, 30, 0.2);
}

.dockare-tableHeader{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.dockare-consultantTableHeader{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #A7A7A7;
}

.dockare-tableText{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(79, 79, 79, 0.8);
}

.table-actionButtonText{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #A7A7A7;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.table-footerSelection{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;
  color: #1B2A37;
}

.table-HeaderAddMultiple{
  background: #F8F8F8;
  border: 0.4px solid #A7A7A7;
  border-radius: 10px;
}
.text-HeaderAddMultiple{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0C4A7C;
}

.table-tableRow:hover{
  background-color: #DAE3E9;
}

.dockare-tableContactText{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.text-dockareBlack{
  color: rgba(105, 105, 105, 1) !important;
}

.text-dockareGray{
  color: rgba(167, 167, 167, 1) !important;
}

.text-dockareSuccess{
  font-family: 'Inter';
  font-style: normal;
  color: rgba(32, 107, 30, 1);
}
.text-dockareError{
  font-family: 'Inter';
  font-style: normal;
  color: rgba(178, 26, 26, 1);
}

.text-dockareDisabled{
  color: rgba(184, 184, 184, 0.8),
}


.text-docID{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.dockare-tableMenu{
  background: #FFFFFF;
  margin: 0px 15px 0px 0px !important;
  box-shadow: 0px 4px 8px rgba(167, 187, 203, 0.64);
  border-radius: 8px 0px 8px 8px !important;
}


.text-addEditModalStatus{
  color: rgba(105, 105, 105, 1);
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.text-dockareFooter{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;
  color: #A8A8A8;
}

.text-dockareHeading{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #0C4A7C;
}

.hopsital-Header{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color:rgba(79, 79, 79, 1);
}

.hospital-Title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(79, 79, 79, 1);
}

.hospital-placeHolder{
  background: #ECECEC;
  border-radius: 8px;
  padding: 15px 22px;
}

.hospital-placeHolderText{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(79, 79, 79, 1);
}

.hospital-addButtonText{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #F8F8F8;
}

.hospital-inputBox{
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
}


.savebar-White{
  padding: 8px 60px;
  background: #F8F8F8;
  border: 0.4px solid #0C4A7C;
  border-radius: 8px;
}

.savebar-WhiteText{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0C4A7C;
}

.savebar-BlueText{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #F8F8F8;
}

.savebar-Blue{
  margin-left: 28px;
  padding: 20px 60px;
  background: #0C4A7C;
  box-shadow: 0px 4px 8px rgba(167, 187, 203, 0.64);
  border-radius: 8px;
}


// this is for the modal

.rightSideBar{
  display: block;
}

.rightSideBar .modal-dialog{
  margin: 0;
  display: flex;
  flex:auto;
  -webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}

.rightSideBar .modal-content{
  box-shadow: 0px 4px 12px rgba(184, 184, 184, 1);
  height: 100vh;
  border-radius: 12px 0px 0px 12px !important;
  background-color: rgba(248, 248, 248, 1);
  overflow-x: hidden;
  overflow-y: scroll;
}



.rightSideBar .fade .modal-dialog {
	left: -320px;
	-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
	-o-transition: opacity 0.3s linear, left 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
	right: 0;
}


// phone number field

.custom-react-tel-input{
  width: 400px !important;
}

.react-tel-input .form-control{
  width: 100% !important;
  box-shadow: none !important;
  height: 40px !important;
}

.react-tel-input :disabled{
  background-color: #e9ecef !important;
}

.react-tel-input .flag-dropdown{
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem 0 0 0.375rem !important;
}
.react-tel-input .selected-flag{
  display: flex;
  justify-content: center;
  padding: 0 !important;
  cursor: auto;
}

.react-tel-input .flag-dropdown .selected-flag:hover{
  background-color: #e9ecef !important;
}

.react-tel-input .flag-dropdown .selected-flag:focus{
  background-color: #e9ecef !important;
}

// OTP field
.otp-input
{
  background-color: #e9ecef;
  border: none;
  height: 40px;
}


// Remving arrows from input type "number"
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
} 



.dockare-btnSuccess{
  // background-color: white !important;
  background-color: rgba(32, 107, 30, 0.12) !important;
}

.dockare-btnSuccess:hover{
  background-color: #198754 !important;
  // color: white !important;
  >svg{
    >path{
      fill: white !important;
    }
  }
}

.dockare-btnError{
  // background-color: white !important;
  background-color: rgba(176, 0, 0, 0.12) !important;
}

.dockare-btnError:hover{
  background-color: #dc3545 !important;
  >svg{
    >path{
      fill: white !important;
    }
  }
  // color: white !important;
}


// sidebar colors
.sidebar-fixed{
  background-color: #EFF2F4 !important;
}

.sidebar-brand{
  background-color: #EFF2F4 !important;
}

.sidebar-toggler{
  background-color: #EFF2F4 !important;
}

.sidebar-nav .nav-group.show{
  background-color: #EFF2F4 !important;
}

.sidebar-nav .nav-link {
  color: #353535 !important;
  margin-left: 14px !important;
  margin-right: 14px !important;
}
.sidebar-nav .nav-link.active {
  background-color: #0C4A7C !important;
  color:#F8F8F8 !important;
  border-radius: 8px !important;

  >.nav-icon{
    color: #F8F8F8 !important;
  }
}

.sidebar-nav .nav-group-items{
  >.nav-item{
    margin:0 15px 0 13px;
    >.nav-link{
      padding-left: 40px !important;
      padding-right: 26px !important;
    }
    >.nav-link.active{
      border-radius: 8px;
      padding: 10px 54px 12px 40px !important;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.sidebar-nav .nav-icon{
  color: #353535 !important;
}


.sidebar-nav .nav-group-toggle::after{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23353535'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
}

.sidebar-nav .nav-title{
  color: #353535 !important;
}

.password-input:focus{
  border: 1px solid #ced4da !important;
  border-right: transparent !important;
}

#consultantTeamExistingMembers{
  scroll-behavior: smooth;
}


#consultantTeamExistingMembers::-webkit-scrollbar{
  width: 5px;
}
#consultantTeamExistingMembers::-webkit-scrollbar-track{
  background-color: lightgray;
  border-radius: 2px;
  margin-top: 12px;
  margin-bottom: 12px;
}
#consultantTeamExistingMembers::-webkit-scrollbar-thumb{
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

#consultantInviteMembers::-webkit-scrollbar{
  display: none;
}
#consultantInviteMembers{
  scroll-behavior: smooth;
}

#constulantTeamInviteMemberSearchList{
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}
#constulantTeamInviteMemberSearchList::-webkit-scrollbar{
  width: 5px;
}
#constulantTeamInviteMemberSearchList::-webkit-scrollbar-track{
  background-color: lightgray;
  border-radius: 2px;
}
#constulantTeamInviteMemberSearchList::-webkit-scrollbar-thumb{
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.analytics-study-modal::-webkit-scrollbar{
  display: none;
}

.analytics-study-modal-table{
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}

.analytics-study-modal-table::-webkit-scrollbar{
  width: 5px;
}

.analytics-study-modal-table::-webkit-scrollbar-track{
  background-color: lightgray;
  border-radius: 2px;
}

.analytics-study-modal-table::-webkit-scrollbar-thumb{
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}


